import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { ProvideEnvironment } from '@rxap/environment';
import { HttpErrorInterceptor, ProvideErrorHandler } from '@rxap/ngx-error';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { ProvideServiceWorkerUpdater, withDialogUpdater, withLogUpdater } from '@rxap/service-worker';
import { KeycloakBearerInterceptor } from '@rxap/keycloak';
import {
  AuthHttpInterceptor,
  CorsInterceptor,
  ProvideAuth,
  ProvideFirebaseMessages,
  ServiceServerApiKeyHttpInterceptor,
  withVapidKey,
} from 'eurogard-authentication';
import { provideServiceWorker } from '@angular/service-worker';
import { CompanySelectInterceptor } from 'angular-http-interceptors/company-select-interceptor';
import { ProvideI18nMatIntl } from 'angular-components/localize/provide';
import { ProvideIconAssetPath } from 'angular-application-providers/provide-icon-asset-path';
import { ProvideErrorInterceptorOptions } from 'angular-application-providers/provide-error-interceptor-options';
import { MarkdownModule } from 'ngx-markdown';
import { LanguageInterceptor } from '@rxap/ngx-localize';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { ProvidePubSub } from '@rxap/ngx-pub-sub';
import { ProvideDate } from 'angular-application-providers/provide-date';
import { provideExternalAppsFilter } from 'angular-application-providers/provide-external-apps-filter';
import { ProvideSetLanguageHook } from 'angular-application-providers/provide-set-language-hook';
import { provideTheme } from 'angular-services/theme/provide';
import { provideSocketIoFromConfig } from 'angular-services/socket-io/provide';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      MarkdownModule.forRoot(),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideHttpClient(withInterceptors([
      HttpErrorInterceptor,
      CompanySelectInterceptor,
      AuthHttpInterceptor,
      CorsInterceptor,
      KeycloakBearerInterceptor,
      ServiceServerApiKeyHttpInterceptor,
      LanguageInterceptor,
    ])),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    ProvideErrorHandler(),
    ProvideAuth(),
    ProvideI18nMatIntl(),
    ProvideIconAssetPath(),
    ProvideErrorInterceptorOptions(),
    ProvideEnvironment(environment),
    provideSocketIoFromConfig(),
    ProvideServiceWorkerUpdater(withLogUpdater(), withDialogUpdater()),
    environment.firebase ? [
      ProvideFirebaseMessages(withVapidKey(environment.firebase.messaging.vapidKey)),
      importProvidersFrom(
        AngularFireModule.initializeApp(environment.firebase.options),
        AngularFireMessagingModule,
      ),
    ] : [],
    ProvidePubSub(),
    ProvideDate(),
    ProvideSetLanguageHook(),
    provideExternalAppsFilter(),
    provideTheme(),
  ],
};
