import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { UserControllerFirebaseWebAppTokenParameter } from '../parameters/user-controller-firebase-web-app-token.parameter';
import { UserControllerFirebaseWebAppTokenRequestBody } from '../request-bodies/user-controller-firebase-web-app-token.request-body';
import { UserControllerFirebaseWebAppTokenResponse } from '../responses/user-controller-firebase-web-app-token.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'user-controller-firebaseWebAppToken',
    operation: '{"operationId":"user-controller-firebaseWebAppToken","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"requestBody":{"content":{"application/json":{"schema":{"type":"object","properties":{"updateMap":{"type":"object","additionalProperties":{"type":"string"}}}}}},"required":true},"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"uuid":{"type":"string","format":"uuid"},"username":{"type":"string"},"activationKey":{"type":"string"},"activationKeyCreationTime":{"type":"integer","format":"int64"},"activated":{"type":"boolean"},"enabled":{"type":"boolean"},"roles":{"type":"array","items":{"$ref":"#/components/schemas/Role"}},"firebaseRegistrationToken":{"type":"string"},"firebaseWebAppMessageToken":{"type":"string"},"receiveMessages":{"type":"boolean"},"favouriteLayout":{"$ref":"#/components/schemas/Layout"},"privacyAccepted":{"type":"boolean"},"privacyAcceptedTimestamp":{"type":"integer","format":"int64"},"desktopDesignMode":{"type":"string","enum":[]},"mobileDesignMode":{"type":"string","enum":[]},"userLanguage":{"type":"string","enum":[]},"receiveEmailMessages":{"type":"boolean"},"telegramApiToken":{"type":"string"},"telegramChatId":{"type":"string"},"notificationChannel":{"type":"integer","format":"int64"},"admin":{"type":"boolean"},"primaryRole":{"$ref":"#/components/schemas/Role"},"removedAt":{"type":"integer","format":"int64"},"__updatedBy":{"type":"string"},"__updatedAt":{"type":"integer","format":"int64"},"__removedAt":{"type":"integer","format":"int64"},"__archived":{"type":"boolean"},"deletedAt":{"type":"integer","format":"int64"},"updatedAt":{"type":"integer","format":"int64"}}}}}}},"method":"put","path":"/user-controller/firebaseWebAppToken/{uuid}"}'
  })
export class UserControllerFirebaseWebAppTokenRemoteMethod extends OpenApiRemoteMethod<UserControllerFirebaseWebAppTokenResponse, UserControllerFirebaseWebAppTokenParameter, UserControllerFirebaseWebAppTokenRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<UserControllerFirebaseWebAppTokenParameter, UserControllerFirebaseWebAppTokenRequestBody>): Promise<UserControllerFirebaseWebAppTokenResponse> {
    return super.call(parameters);
  }
}
