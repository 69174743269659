import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { UserControllerUpdateUserLanguageRequestBody } from '../request-bodies/user-controller-update-user-language.request-body';
import { UserControllerUpdateUserLanguageResponse } from '../responses/user-controller-update-user-language.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'user-controller-updateUserLanguage',
    operation: '{"operationId":"user-controller-updateUserLanguage","requestBody":{"content":{"application/json":{"schema":{"type":"object","properties":{"updateMap":{"type":"object","additionalProperties":{"type":"string"}}}}}},"required":true},"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"uuid":{"type":"string","format":"uuid"},"username":{"type":"string"},"activationKey":{"type":"string"},"activationKeyCreationTime":{"type":"integer","format":"int64"},"activated":{"type":"boolean"},"enabled":{"type":"boolean"},"roles":{"type":"array","items":{"$ref":"#/components/schemas/Role"}},"firebaseRegistrationToken":{"type":"string"},"firebaseWebAppMessageToken":{"type":"string"},"receiveMessages":{"type":"boolean"},"favouriteLayout":{"$ref":"#/components/schemas/Layout"},"privacyAccepted":{"type":"boolean"},"privacyAcceptedTimestamp":{"type":"integer","format":"int64"},"desktopDesignMode":{"type":"string","enum":[]},"mobileDesignMode":{"type":"string","enum":[]},"userLanguage":{"type":"string","enum":[]},"receiveEmailMessages":{"type":"boolean"},"telegramApiToken":{"type":"string"},"telegramChatId":{"type":"string"},"notificationChannel":{"type":"integer","format":"int64"},"admin":{"type":"boolean"},"primaryRole":{"$ref":"#/components/schemas/Role"},"removedAt":{"type":"integer","format":"int64"},"__updatedBy":{"type":"string"},"__updatedAt":{"type":"integer","format":"int64"},"__removedAt":{"type":"integer","format":"int64"},"__archived":{"type":"boolean"},"deletedAt":{"type":"integer","format":"int64"},"updatedAt":{"type":"integer","format":"int64"}}}}}}},"method":"put","path":"/user-controller/user-language"}'
  })
export class UserControllerUpdateUserLanguageRemoteMethod extends OpenApiRemoteMethod<UserControllerUpdateUserLanguageResponse, void, UserControllerUpdateUserLanguageRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<void, UserControllerUpdateUserLanguageRequestBody>): Promise<UserControllerUpdateUserLanguageResponse> {
    return super.call(parameters);
  }
}
